<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8" lg="5">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4 px-5">
              <CForm class="d-flex flex-column">
                <p class="text-center font-weight-bold mb-4 text-uppercase">Login</p>
                <CInput
                    :placeholder="'E-mail'"
                    autocomplete="email"
                    v-model="formData.email"
                    :addInputClasses="{'is-invalid': hasError('email')}"
                    :invalid-feedback="getError('email')"
                >
                  <template #append-content>
                    <i class="far fa-envelope"/>
                  </template>
                </CInput>
                <CInput
                    :placeholder="'Password'"
                    :type="passwordOpen ? 'text': 'password'"
                    autocomplete="current-password"
                    v-model="formData.password"
                    :addInputClasses="{'is-invalid': hasError('password')}"
                    :invalid-feedback="getError('password')"
                >
                  <template #append-content>
                    <i class="fas " :class="{'fa-eye': !passwordOpen, 'fa-eye-slash': passwordOpen}" @click="passwordOpen = !passwordOpen"/>
                  </template>
                </CInput>
                <CInputCheckbox label="Remember me" :checked="formData.remember"
                                @update:checked="formData.remember = $event">Remember Me
                </CInputCheckbox>
                <CButton color="primary" class="mt-3 px-5 mb-3 align-self-center text-uppercase" @click="login"
                >
                  <span v-if="!loading">Login</span>
                  <CSpinner v-else size="sm"></CSpinner>
                </CButton>
                <router-link :to="{ name: 'Forgot Password'}" class="d-block text-center px-0 font-weight-bold">
                  Forgot Password?
                </router-link>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: 'Login',
  components: {},
  mixins: [hasApiValidation],
  data() {
    return {
      formData: {
        email: '',
        password: '',
        remember: false,
      },
      passwordOpen: false,
      registered: false,
      loading: false,
    }
  },
  mounted() {
  },


  methods: {
    login() {
      this.setErrors({});
      this.loading = true;
      this.$auth
          .login({
            data: this.formData,
            redirect: {name: 'Dashboard'},
            remember: 'Spinning_Admin',
            staySignedIn: this.formData.remember,
            fetchUser: true
          })
          .catch(({response}) => {
            // if (response && response.data.message) {
            //   this.$noty.error(response.data.message);
            // }
            this.setErrors(response.data.errors);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  watch: {}
}
</script>
